import React, { useContext } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { OrderCarContext } from "../../context/OrderContext";
import { ContentContext } from "../layout";

interface Props {
  imageSrc: string;
  brand: string;
  price: number;
  productName: string;
  active: boolean;
  id: string;
}

const cardImageStyle = {
  width: "inherit",
  borderRadius: "6px",
  paddingTop: "1px",
  height: " 70%",
  objectFit: "contain",
};

const cardStyle = (active: boolean) => {
  return {
    background: active ? "#f2f2f2" : "white",
    padding: 2,
    borderRadius: 2,
    minHeight: "270px",
    boxShadow: !active
      ? "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
      : "none",
    cursor: 'pointer',
    width: 280
  };
};


const ProductPrimaryCard: React.FC<Props> = ({
  imageSrc,
  brand,
  price,
  productName,
  active,
  id
}) => {
  const { setSelectedCar, setVisibleSection, selectedCar, visibleSection } = useContext(OrderCarContext);
   const {translate} = useContext(ContentContext)
  return (
    <Box
      sx={cardStyle(active)}
      onClick={() => {
        setSelectedCar({ id, name: productName, price, imageUrl: imageSrc });
        setVisibleSection('calculatePrice');
      }}
    >
      <Box component="img" src={imageSrc} sx={cardImageStyle} loading='lazy' />
      <Box>
        <Typography color='#302f2f' fontWeight={200}>{brand}</Typography>
        <Typography color='#302f2f' fontWeight={700} fontSize="14px">{productName}</Typography>

        <Typography color='#302f2f' fontSize="small" mt={1}>
          <span style={{ fontWeight: 700 }}>XAF {price}k </span> /{translate("day")}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProductPrimaryCard;
