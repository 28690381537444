import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FormControlLabel,
  FormGroup,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import {
  Calendar,
  DateObject,
  getAllDatesInRange,
} from "react-multi-date-picker";
import "react-calendar/dist/Calendar.css";
import "./_index.css";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import AdjustIcon from "@mui/icons-material/Adjust";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PlaceIcon from "@mui/icons-material/Place";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Navbar from "../../navbar";
import french_calendar from "../../../../locales/fr/calendar_fr";
import gregorian_en from "react-date-object/locales/gregorian_en";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/system";
import { OrderCarContext } from "../../../context/OrderContext";
import { ContentContext } from "../../layout";
import { UserContext } from "../../../context/AuthContext";
import axios from "axios";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { API_URL } from "../../../utils/constants";
import Loader from "../../Loader";
import { BackupTable, Close } from "@mui/icons-material";
import Lazy from "../../lazyAnimation";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Skeleton from '@mui/material/Skeleton';


declare var google: any;

const inputStyles = {
  p: "2px 4px",
  display: "flex",
  alignItems: "center",
  background: "#f2f2f2",
  boxShadow: "none",
  borderRadius: "4px",
};

const arrayInputFieldContainer = {
  display: "flex",
  alignItems: "center",
  padding: "10px",
  boxShadow: "none",
  borderRadius: "4px",
};

interface Props {
  isAuth: boolean;
}

const CalculatePriceForm = () => {
  const ln_local =
    typeof window !== "undefined" ? localStorage.getItem("defaultLanguage") : null;

  const [calendarLang, setCalendarLang] = useState(gregorian_en);
  const { translate } = useContext(ContentContext);

  const schema = yup.object().shape({
    pick_up_address: yup.string().required(translate("required_field")),
    destinationOptions: yup.mixed().oneOf(["i", "o", "b"]).required(),
    destination: yup.string().when("destinationOptions", {
      is: (val: any) => val === "b" || val === "o",
      then: yup.string().required(translate("required_field")),
    }),
    daysInTown: yup.number().when("destinationOptions", {
      is: (val: any) => val === "b",
      then: yup
        .number()
        .integer()
        .min(1, translate("minimum_number_of_reservations_is_one_day"))
        .required(translate("required_field")),
    }),
    daysOutOfTown: yup.number().when("destinationOptions", {
      is: (val: any) => val === "b",
      then: yup
        .number()
        .integer()
        .min(1, translate("minimum_number_of_reservations_is_one_day"))
        .required(translate("required_field")),
    }),
  });

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    background: "#fff",
    width: "300px",
    borderRadius: "6px",
  };

  const [limitError, setLimitError] = useState<boolean>(false);
  const inputDestination = useRef<HTMLInputElement>(null);
  const {
    selectedCar,
    setVisibleSection,
    visibleSection,
    datesToBeDisabled,
    pointOfDeparture,
    setPointOfDeparture,
    setSelectedDates,
    selectedDates,
    des,
    setDestination,
    destinationOptions,
    daysInTown: DIT,
    daysOutOfTown: DOT,
    setTargetDestination,
    price,
    setPrice,
    setDaysOutOfTown,
    setDaysInTown,
    selectRange,
    setSelectRange,
    setUserInfo,
    setSelectedCar,
    setDatesToBeDisabled,
    cancelSelectedDates,
    setCancelSelectedDates,
  } = useContext(OrderCarContext);
  const {
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: {
      destinationOptions: !destinationOptions ? "i" : destinationOptions,
      pick_up_address: pointOfDeparture,
      destination: des,
      daysInTown: DIT,
      daysOutOfTown: DOT,
    },
  });
  const scope = watch("destinationOptions") as "i" | "o" | "b" | undefined;
  const pickUpAddress = watch("pick_up_address");
  const destination11 = watch("destination");
  const watchDaysInTown = watch("daysInTown");
  const watchDaysOutOfTown = watch("daysOutOfTown");

  const [loadDisableDates, setLoadingDisableDates] = useState<boolean>(true);
  const [leadingZeroError, setLeadingZeroError] = useState<boolean>(false);
  const [chooseTwoDatesAndAboveError, setChooseChooseTwoDatesAndAboveError] =
    useState<boolean>(false);
  const [limitNumberOfDays, setLimitNumberOfDays] = useState<boolean>(false);

  const getBookedDays = () => {
    let data = {
      carName: selectedCar?.name,
    };
    axios
      .post(`${API_URL}/getBookedDays`, data)
      .then((r) => {
        setDatesToBeDisabled(
          r.data.flatMap((day: any) =>
            day[0]?.map((day: any) => day.split("T")[0])
          )
        );
        setLoadingDisableDates(false);
      })
      .catch((e) => { });
  };

  useEffect(() => {
    cancelSelectedDates ? setSelectedDates([]) : null;
    cancelSelectedDates ? setCancelSelectedDates(false) : null;
  }, []);

  useEffect(() => {
    getBookedDays();
    return () => { };
  }, [selectedCar?.name]);

  useEffect(() => {
    !destinationOptions
      ? setTargetDestination(scope!)
      : setTargetDestination(destinationOptions);
  }, []);

  useEffect(() => {
    reset({
      ...getValues,
      destinationOptions: destinationOptions,
    });

    setValue("pick_up_address", "Odza, Yaoundé");
    setValue("destination", getValues().destination || des);

    return () => { };
  }, [selectRange]);

  const handleSelectDateRange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectRange(event.target.checked);
    setSelectedDates([]);
    setValue("daysInTown", 0);
    setValue("daysOutOfTown", 0);
    setDaysInTown(0);
    setDaysOutOfTown(0);
  };

  useEffect(() => {
    if (scope === "b") {
      setValue("daysOutOfTown", Number(DOT) || "");
      setValue("daysInTown", Number(DIT) || "");
    }
  }, [scope]);

  useEffect(() => {
    if (selectedDates.length > 1 && selectedDates.length <= 2) {
      setValue("daysInTown", 1, { shouldValidate: true });
      setDaysInTown(1);
      setValue("daysOutOfTown", 1, { shouldValidate: true });
      setDaysOutOfTown(1);
    } else if (selectedDates.length > 2) {
      let daysOutTown = Number(DOT);
      let daysInTown = Number(DIT);
      if (daysInTown > 1) {
        setDaysInTown(selectedDates.length - (daysOutTown || 1));
        setValue("daysInTown", selectedDates.length - (daysOutTown || 1), {
          shouldValidate: true,
        });
      } else if (daysInTown === 1 && daysOutTown > 1) {
        setDaysOutOfTown(selectedDates.length - (daysInTown || 1));
        setValue("daysOutOfTown", selectedDates.length - (daysInTown || 1), {
          shouldValidate: true,
        });
      } else {
        setDaysInTown(selectedDates.length - (daysOutTown || 1));
        setValue("daysInTown", selectedDates.length - (daysOutTown || 1), {
          shouldValidate: true,
        });
      }
      if (selectRange) {
        setDaysOutOfTown(Number(DOT) || 1);
        setValue("daysOutOfTown", Number(DOT) || 1);
      }
    }
    return () => { };
  }, [selectedDates.length]);

  const getDate = (selectedDates: DateObject | DateObject[] | null) => {
    if (selectedDates) {
      if (selectRange && !(selectedDates instanceof DateObject)) {
        const finale: Array<string> = [];
        const datesList = getAllDatesInRange(selectedDates).map(
          (e) =>
            (e instanceof Date ? e : e.toDate()).toISOString().split("T")[0]
        );
        datesList.map((d) => {
          if (!datesToBeDisabled.includes(d)) {
            finale.push(d);
          }
        });
        setSelectedDates(finale.map((d) => new Date(d)));
        return;
      }
      if (selectedDates instanceof DateObject) {
        setSelectedDates([selectedDates.toDate()]);
      } else {
        setSelectedDates([...selectedDates.map((d) => d.toDate())]);
      }
    }
  };

  useEffect(() => {
    setCalendarLang(ln_local === "EN" ? gregorian_en : french_calendar);
  }, []);

  useEffect(() => {
    async function fetchCurrentPlace() {
      if (pointOfDeparture?.length || 0 <= 0) {
        setPointOfDeparture("Odza, Yaoundé");
        setValue("pick_up_address", "Odza, Yaoundé", { shouldValidate: true });
      } else {
        setPointOfDeparture(pointOfDeparture);
        setValue("pick_up_address", pointOfDeparture, { shouldValidate: true });
      }
    }
    fetchCurrentPlace();
    return () => { };
  }, []);

  useEffect(() => {
    function getPlaces() {
      const options = {
        fields: ["formatted_address"],
        strictBounds: false,
        types: ["geocode"],
        componentRestrictions: {
          country: "cm",
        },
      };
      if (destination11?.length || [].length > 0) {
        const input = document.getElementById(
          "destination"
        ) as HTMLInputElement;
        try {
          const autocomplete = new google.maps.places.Autocomplete(
            input,
            options
          );
          google.maps.event.addListener(autocomplete, "place_changed", () => {
            setValue("destination", autocomplete.getPlace().formatted_address);
            setDestination(autocomplete.getPlace().formatted_address);
          });
        } catch (e) { }
      }
    }
    getPlaces();
    return () => { };
  }, [destination11]);

  useEffect(() => {
    if (selectedDates.length <= 30) {
      setLimitNumberOfDays(false);
      if (scope === "i") {
        setChooseChooseTwoDatesAndAboveError(false);
        const price = (selectedCar?.price || 0) * 1000 * selectedDates.length;
        setPrice(price);
      } else if (scope === "o") {
        setChooseChooseTwoDatesAndAboveError(false);
        const price =
          ((selectedCar?.price || 0) + 10) * 1000 * selectedDates.length;
        setPrice(price);
      } else if (scope === "b") {
        if (selectedDates.length <= 1) {
          setPrice(0);
          setChooseChooseTwoDatesAndAboveError(true);
          return;
        }
        setChooseChooseTwoDatesAndAboveError(false);
        const priceInTown =
          (selectedCar?.price || 0) * 1000 * (Number(watchDaysInTown) | 0);
        const priceOutOfTown =
          ((selectedCar?.price || 0) + 10) *
          1000 *
          (Number(watchDaysOutOfTown) | 0);
        setPrice(priceInTown + priceOutOfTown);
      }
    } else if (selectedDates.length > 30) {
      setLimitNumberOfDays(true);
    }
  }, [selectedDates.length, watchDaysInTown, watchDaysOutOfTown, scope]);

  const { errors, isSubmitting } = formState;
  const { isAuth, user, signInUser } = useContext(UserContext);

  useEffect(() => {
    setValue("destination", getValues().destination || des);
  }, [visibleSection]);

  const resetForm = () => {
    if (
      typeof window !== "undefined" &&
      confirm(translate("reset_form_warning_message"))
    ) {
      reset();
      setTargetDestination("i");
      setDestination("");
      setPointOfDeparture("");
      setSelectedDates([]);
      setUserInfo({ name: "", phoneNumber: "" });
      setDaysInTown(0);
      setDaysOutOfTown(0);
      setPrice(0);
      setSelectRange(false);
    }
  };

  const onSubmit = () => {
    setVisibleSection("bookACar");
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#302f2f',
      }
    },
  });

  return (
    <>
        <div id='container-cars'>
          <Lazy type='slide' delay={150}  timeout={1000} direction='left' className='slide-container'>
            <div className="frst">
              <div className="snd">
                  <div className="cars">
                    <Navbar title={translate("rent_car")} previous="index" />
                    <br />
                    <ThemeProvider theme={theme}>
                      <FormGroup>
                        <FormControlLabel
                          value="i"
                          control={
                            <Switch
                              checked={selectRange}
                              onChange={handleSelectDateRange}
                              color="primary"
                            />
                          }
                          label={<Typography sx={{ fontSize: '16px', fontWeight: '500' }}
                            color='#302f2f'>{translate("select_a_range_of_date")}</Typography>}
                        />
                      </FormGroup>
                    </ThemeProvider>
                    <>
                      {loadDisableDates && (
                        <Skeleton
                          animation='pulse'
                          sx={{ bgcolor: 'grey.500', width: '100%', height: '250px', borderRadius: '10px', marginBottom: '10px' }}
                          variant="rectangular"
                        />
                      )}
                      {!loadDisableDates && (
                        <Calendar
                        locale={calendarLang}
                        minDate={new Date()}
                        onChange={getDate}
                        sort
                        value={selectedDates}
                        range={selectRange}
                        multiple
                        className="dark"
                        mapDays={({ date }) => ({
                          disabled: datesToBeDisabled.includes(
                            date.toDate().toISOString().split("T")[0]
                          ),
                        })}
                        />
                      )}
                    </>
                    {chooseTwoDatesAndAboveError && (
                      <Typography fontSize="x-small" color={"red"}>
                        {translate("minimum_number_of_reservations_is_two_days")}
                      </Typography>
                    )}
                    <br />
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Typography color='#302f2f' variant="subtitle1" sx={{ fontSize: '16px', fontWeight: '500' }}>
                        {translate("booking_options")}
                      </Typography>
                      <Controller
                        control={control}
                        name="destinationOptions"
                        render={({ field }) => (
                          <RadioGroup {...field} sx={{ flexDirection: "row" }}>
                            <ThemeProvider theme={theme}>
                              <FormControlLabel
                                value="i"
                                control={
                                  <Radio
                                    onClick={() => {
                                      setValue("destinationOptions", "i");
                                      setTargetDestination("i");
                                    }}
                                    checked={scope === "i"}
                                    color="primary"
                                  />
                                }
                                label={<Typography sx={{ fontSize: '15px', fontWeight: 'regular' }}
                                  color='#302f2f'>{translate("in_town")}</Typography>}
                              />
                            </ThemeProvider>
                            <ThemeProvider theme={theme}>
                              <FormControlLabel
                                value="o"
                                control={
                                  <Radio
                                    onClick={() => {
                                      setValue("destinationOptions", "o");
                                      setTargetDestination("o");
                                    }}
                                    checked={scope === "o"}
                                    color="primary"
                                  />
                                }
                                label={<Typography sx={{ fontSize: '15px', fontWeight: 'regular' }}
                                  color='#302f2f'>{translate("out_of_town")}</Typography>}
                              />
                            </ThemeProvider>
                            <ThemeProvider theme={theme}>
                              <FormControlLabel
                                value="b"
                                control={
                                  <Radio
                                    onClick={() => {
                                      setValue("destinationOptions", "b");
                                      setTargetDestination("b");
                                    }}
                                    checked={scope === "b"}
                                    color="primary"
                                  />
                                }
                                label={<Typography sx={{ fontSize: '15px', fontWeight: 'regular' }}
                                  color='#302f2f'>{translate("both")}</Typography>}
                              />
                            </ThemeProvider>
                          </RadioGroup>
                        )}
                      />
                      {errors.destinationOptions && (
                        <Typography variant="caption" color="red" fontSize="x-small">
                          {errors?.destinationOptions?.message}
                        </Typography>
                      )}
                      <Typography sx={{ fontSize: '16px', fontWeight: '500' }} variant="subtitle1" color='#302f2f'>
                        {translate("pick_up_address")}
                      </Typography>
                      <Paper sx={inputStyles}>
                        <IconButton sx={{ p: "10px" }}>
                          <AdjustIcon />
                        </IconButton>
                        <Controller
                          control={control}
                          name="pick_up_address"
                          render={({ field: { name } }) => (
                            <InputBase
                              sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: '300' }}
                              placeholder="Odza, Yaoundé*"
                              inputProps={{
                                "aria-label": translate("search_google_maps"),
                              }}
                              name={name}
                              id="destination"
                              inputRef={inputDestination}
                              onChange={(e) => {
                                setPointOfDeparture(e.target.value);
                                setValue("pick_up_address", e.target.value, {
                                  shouldValidate: true,
                                });
                              }}
                              value={getValues().pick_up_address}
                            />
                          )}
                        ></Controller>
                      </Paper>
                      {errors.pick_up_address && (
                        <Typography variant="caption" color="red" fontSize="x-small">
                          {errors?.pick_up_address?.message}
                        </Typography>
                      )}

                      {(scope === "b" || scope === "o") && (
                        <>
                          <br />
                          <Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: '500' }} variant="subtitle1">Destination</Typography>
                          <Paper sx={inputStyles}>
                            <IconButton sx={{ p: "10px" }}>
                              <PlaceIcon />
                            </IconButton>
                            <Controller
                              control={control}
                              name="destination"
                              render={({ field: { name } }) => (
                                <InputBase
                                  sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: '300' }}
                                  placeholder="Bastos, Yaoundé*"
                                  inputProps={{
                                    "aria-label": translate("search_google_maps"),
                                  }}
                                  name={name}
                                  id="destination"
                                  inputRef={inputDestination}
                                  onChange={(e) => {
                                    setDestination(e.target.value);
                                    setValue("destination", e.target.value, {
                                      shouldValidate: true,
                                    });
                                  }}
                                  value={getValues().destination || des}
                                />
                              )}
                            ></Controller>
                          </Paper>
                          {errors.destination && (
                            <Typography variant="caption" color="red" fontSize="x-small">
                              {errors?.destination?.message}
                            </Typography>
                          )}
                        </>
                      )}
                      {scope === "b" && selectedDates.length > 1 && (
                        <>
                          <br />
                          <Box sx={{ display: "flex", columnGap: "4px" }}>
                            <Box width="100%">
                              <Typography color='#302f2f' variant="subtitle1" sx={{ fontSize: '16px', fontWeight: '500' }}>
                                {translate("days_in_town")}
                              </Typography>
                              <Paper
                                sx={{
                                  ...arrayInputFieldContainer,
                                  marginBottom: "4px",
                                  rowGap: "4px",
                                  background: "#f2f2f2",
                                }}
                              >
                                <Controller
                                  control={control}
                                  name="daysInTown"
                                  render={({ field: { name } }) => (
                                    <InputBase
                                      sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: '300' }}
                                      name={name}
                                      placeholder={translate("days_in_town")}
                                      type="number"
                                      disabled={selectedDates.length <= 1}
                                      inputProps={{
                                        min: 1,
                                      }}
                                      onKeyDown={(e: any) => {
                                        var key = e.keyCode || e.charCode;
                                        if (key == 8 || key == 46) {
                                          if (DIT < 10) {
                                            setValue("daysInTown", 0, {
                                              shouldValidate: true,
                                            });
                                            setDaysInTown("");

                                            setValue("daysOutOfTown", 0, {
                                              shouldValidate: true,
                                            });
                                            setDaysOutOfTown("");
                                          }
                                        }
                                        if (e.key === "0" && DIT.toString().length <= 0) {
                                          setLeadingZeroError(true);
                                          setValue("daysInTown", 0, {
                                            shouldValidate: true,
                                          });
                                          setDaysInTown("");
                                          e.preventDefault();
                                        } else {
                                          setLeadingZeroError(false);
                                        }
                                      }}
                                      onChange={(e: any) => {
                                        if (!/[0-9]/.test(e.target.value)) {
                                          e.preventDefault();
                                          return;
                                        }
                                        if (
                                          e.target.valueAsNumber >
                                          selectedDates.length - 1
                                        ) {
                                          e.preventDefault();
                                          setLimitError(true);
                                          return;
                                        } else {
                                          setLimitError(false);
                                          setValue(
                                            "daysInTown",
                                            parseInt(e?.target?.value, 10),
                                            {
                                              shouldValidate: true,
                                            }
                                          );
                                          setDaysInTown(
                                            e?.target?.value as unknown as number
                                          );
                                          setValue(
                                            "daysOutOfTown",
                                            selectedDates.length -
                                            Number(getValues().daysInTown),
                                            { shouldValidate: true }
                                          );
                                          setDaysOutOfTown(
                                            selectedDates.length -
                                            Number(getValues().daysInTown)
                                          );
                                        }
                                      }}
                                      value={parseInt(DIT as unknown as string, 10) || ""}
                                    />
                                  )}
                                ></Controller>
                              </Paper>
                              {errors.daysInTown && (
                                <Typography
                                  variant="caption"
                                  color="red"
                                  fontSize="x-small"
                                >
                                  {errors?.daysInTown?.message}
                                </Typography>
                              )}
                            </Box>
                            <Box width="100%">
                              <Typography color='#302f2f' variant="subtitle1" sx={{ fontSize: '16px', fontWeight: '500' }}>
                                {translate("days_out_of_town")}
                              </Typography>
                              <Paper
                                sx={{
                                  ...arrayInputFieldContainer,
                                  marginBottom: "4px",
                                  rowGap: "4px",
                                  background: "#f2f2f2",
                                  position: "relative",
                                }}
                              >
                                <Controller
                                  control={control}
                                  name="daysOutOfTown"
                                  render={({ field: { name } }) => (
                                    <InputBase
                                      sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: '300' }}
                                      placeholder={translate("days_out_of_town")}
                                      name={name}
                                      type="number"
                                      disabled={selectedDates.length <= 1}
                                      inputProps={{
                                        min: 1,
                                      }}
                                      onKeyDown={(e: any) => {
                                        var key = e.keyCode || e.charCode;
                                        if (key == 8 || key == 46) {
                                          if (DOT < 10) {
                                            setValue("daysInTown", 0, {
                                              shouldValidate: true,
                                            });
                                            setDaysInTown("");

                                            setValue("daysOutOfTown", 0, {
                                              shouldValidate: true,
                                            });
                                            setDaysOutOfTown("");
                                          }
                                        }
                                        if (e.key === "0" && DIT.toString().length <= 0) {
                                          setLeadingZeroError(true);
                                          setValue("daysInTown", 0, {
                                            shouldValidate: true,
                                          });
                                          setDaysInTown("");
                                          e.preventDefault();
                                        } else {
                                          setLeadingZeroError(false);
                                        }
                                      }}
                                      onChange={(e: any) => {
                                        if (!/[0-9]/.test(e.target.value)) {
                                          e.preventDefault();
                                          return;
                                        }
                                        if (
                                          e.target.valueAsNumber >
                                          selectedDates.length - 1 ||
                                          e.target.valueAsNumber < 0
                                        ) {
                                          e.preventDefault();
                                          setLimitError(true);
                                          return;
                                        } else {
                                          setLimitError(false);
                                          setValue(
                                            "daysOutOfTown",
                                            parseInt(e?.target?.value, 10),
                                            { shouldValidate: true }
                                          );
                                          setDaysOutOfTown(
                                            e?.target?.value as unknown as number
                                          );
                                          setValue(
                                            "daysInTown",
                                            selectedDates.length -
                                            Number(getValues().daysOutOfTown) || 0,
                                            { shouldValidate: true }
                                          );
                                          setDaysInTown(
                                            selectedDates.length -
                                            Number(getValues().daysOutOfTown) || 0
                                          );
                                        }
                                      }}
                                      value={parseInt(DOT as unknown as string, 10) || ""}
                                    />
                                  )}
                                ></Controller>
                              </Paper>
                              {errors.daysOutOfTown && (
                                <Typography
                                  variant="caption"
                                  color="red"
                                  fontSize="x-small"
                                >
                                  {errors?.daysOutOfTown?.message}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                          {limitError && (
                            <Typography color={`red`} fontSize="x-small">
                              {translate(
                                "you_cannot_exceed_the_number_of_days_selected_in_the_calendar"
                              )}
                            </Typography>
                          )}
                          {leadingZeroError && (
                            <Typography color={`red`} fontSize="x-small">
                              {translate("minimum_number_of_reservations_is_one_day")}
                            </Typography>
                          )}
                        </>
                      )}

                      <Box mt={1}>
                        <Typography
                          variant="body1"
                          color='#302f2f' sx={{ fontSize: '12px', fontWeight: '500' }}
                          position="relative"
                        >
                          XAF 10k {translate("more_for_city_exits_on_all_our_vehicles")}
                        </Typography>
                        {selectedDates.length > 1 && (
                          <Grid container spacing={4} mt={0}>
                            <Grid item xs={6}>
                              <Typography color='#302f2f' variant="subtitle1" sx={{ fontSize: '16px', fontWeight: '500' }}>
                                {translate("start")}
                              </Typography>
                              <Paper sx={inputStyles}>
                                <IconButton sx={{ p: "5px" }}>
                                  <CalendarMonthIcon />
                                </IconButton>

                                <InputBase
                                  sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: '300' }}
                                  placeholder={selectedDates[0].toLocaleDateString(
                                    "en-GB"
                                  )}
                                  disabled
                                />
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography color='#302f2f' variant="subtitle1" sx={{ fontSize: '16px', fontWeight: '500' }}>
                                {translate("end")}
                              </Typography>
                              <Paper sx={inputStyles}>
                                <IconButton sx={{ p: "5px" }}>
                                  <CalendarMonthIcon />
                                </IconButton>
                                <InputBase
                                  sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: '300' }}
                                  placeholder={selectedDates[
                                    selectedDates.length - 1
                                  ].toLocaleDateString("en-GB")}
                                  disabled
                                />
                              </Paper>
                            </Grid>
                          </Grid>
                        )}
                        <Grid container spacing={4} mt={1} className="totalContainer">
                          <Grid item xs={6} className="total" alignSelf="center">
                            <Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: '500' }}>
                              {translate("total_for")} {selectedDates.length}{" "}
                              {translate("day")}(s)
                            </Typography>
                          </Grid>
                          <Grid item xs={6} className="totalAmount">
                            <Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: '500' }} variant="h5">
                              <span className="curency">XAF</span>{" "}
                              {new Intl.NumberFormat().format(price)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            overflow: "hidden",
                            width: "100%",
                            height: "180px",
                            marginTop: "20px",
                            backgroundColor: 'white',
                            borderRadius: '5px',
                            boxShadow: '0px 0px 4px #c5c2c2',
                          }}
                        >
                          <Box
                            component="img"
                            src={selectedCar?.imageUrl}
                            sx={{
                              height: "80%",
                              transform: "translate(-50%)",
                              marginLeft: "50%",
                              marginTop: "20px",
                            }}
                            loading='lazy'
                          />
                        </Box>
                      </Box>
                      <Stack direction={"row"} columnGap={2} alignItems="end">
                        <Button
                          variant="text"
                          onClick={resetForm}
                          sx={{
                            height: "50px",
                            background: "#f2f2f2",
                            "&:hover": { background: "#f2f2f2!important" },
                          }}
                        >
                          <Typography color={"black"} fontSize="16px">
                            <RestartAltIcon sx={{ color: "black", fontSize: "34px" }} />
                          </Typography>
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          className="primary"
                          type="submit"
                          endIcon={<ArrowForwardIcon sx={{ color: 'white' }} />}
                          sx={{
                            height: 50,
                            borderRadius: "6px",
                            textTransform: "initial",
                            fontSize: "16px",
                            "&:disabled": {
                              "-webkit-text-fill-color": "#c6c6c6 !important",
                            },
                            marginTop: "10px",
                            flexGrow: 1,
                          }}
                          disabled={
                            scope === "b"
                              ? selectedDates.length !==
                              ((parseInt(watchDaysInTown as unknown as string) +
                                parseInt(watchDaysOutOfTown as unknown as string)) |
                                (parseInt(DIT as unknown as string) +
                                  parseInt(DOT as unknown as string))) ||
                              selectedDates.length > 30
                              : selectedDates.length < 1 && selectedDates.length < 31
                          }
                        >
                          {isAuth ? (
                            isSubmitting ? (
                              <span style={{ color: "#fff!important" }}>
                                {translate("booking_in_progress")}
                              </span>
                            ) : (
                              translate("book_car")
                            )
                          ) : (
                            translate("next")
                          )}
                        </Button>
                      </Stack>
                    </form>
                    <Modal
                      open={limitNumberOfDays}
                      onClose={() => {
                        setLimitNumberOfDays(false);
                        setSelectedDates([]);
                      }}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={{ ...style, position: "relative" }}>
                        <Close
                          onClick={() => {
                            setLimitNumberOfDays(false);
                            setSelectedDates([]);
                          }}
                          sx={{ position: "absolute", right: "10px", top: "15px" }}
                        />
                        <Typography
                          color="orange"
                          id="modal-modal-description"
                          sx={{ mt: 2 }}
                        >
                          {translate("limit_number_of_days")}
                        </Typography>
                      </Box>
                    </Modal>
                  </div>
              </div>
            </div>
          </Lazy>
        </div>
    </>
  );
};

export default CalculatePriceForm;
