import React, { useContext } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { OrderCarContext } from "../../context/OrderContext";
import { ContentContext } from "../layout";
interface Props {
  id:string;
  imageSrc: string;
  brand: string;
  carName: string;
  price: number;
}

const boxStyle = {
  display: "flex",
  borderRadius: 3,
  background: "#f2f2f2",
  gap: 2,
  padding: "0px 8px",
  minWidth: "300px",
  cursor: 'pointer'
};

const ProductSecondaryCard: React.FC<Props> = ({
  imageSrc,
  brand,
  carName,
  price,
  id,
}) => {
  const { setSelectedCar, setVisibleSection } =
    useContext(OrderCarContext);
  const { translate } = useContext(ContentContext)
  return (
    <Box
      sx={boxStyle}
      onClick={() => {
        setSelectedCar({ id, name: carName, price: price, imageUrl: imageSrc });
        setVisibleSection("calculatePrice");
      }}
    >
      <Box
        sx={{
          background: "white",
          width: "100px",
          height: "88px",
          margin: "auto 0",
          borderRadius: 2,
        }}
      >
        <Box component="img" src={imageSrc} width={100} loading='lazy' sx={{height: '80%', objectFit: 'contain'}} />
      </Box>

      <Box padding="10px 0">
        <Typography color='#302f2f' fontWeight={200} variant="caption">{brand}</Typography>
        <Typography color='#302f2f' fontSize="14px" mt={0}>
          <strong>{carName}</strong>
        </Typography>
        <Typography color='#302f2f' fontSize="small" mt={2}>
          <span style={{ fontWeight: 700 }}>XAF {price}k </span>/{translate("day")}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProductSecondaryCard;
