import React, { useState } from "react";
import { Script } from "gatsby";
import "../../index.css";
import "react-calendar/dist/Calendar.css";
import { Car, OrderCarContext, UserInfo } from "../../context/OrderContext";
import CarShowCase from "../../components/section/bookACar/CarShowCase";
import { BookingACarForm, OrderSummary, CalculatePriceForm } from "../../components/section/bookACar";
import Layout from "../../components/layout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessFullOrder from "../../components/section/bookACar/SuccessfullOrder";

const IndexPage = () => {
  const [visibleSectionState, setVisibleSectionAction] = useState<
    "index" | "calculatePrice" | "bookACar" | "summary" | "successfullOrderPlaced"
  >("index");
  const [selectedCarState, setSelectedCarAction] = useState<Car | null>(null);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [cancelSelectedDates, setCancelSelectedDates] = useState<boolean>(false);

  const [userInfo, setUserInfo] = useState<UserInfo>({
    name: "",
    phoneNumber: ""
  });
  const [destinationOptions, setTargetDestination] = useState<"i" | "o" | "b">(
    "i"
  );
  const [pointOfDeparture, setPointOfDeparture] = useState<string | null>("");
  const [des, setDestination] = useState<string | null>("");
  const [daysInTown, setDaysInTown] = useState<number | string>('');
  const [daysOutOfTown, setDaysOutOfTown] = useState<number | string>('');
  const [price, setPrice] = useState<number>(0);
  const [selectRange, setSelectRange] = useState<boolean>(false);
  const [disabledDates, setDisabledDates] = useState<string[]>([]);

  return (
    <Layout>
      <OrderCarContext.Provider
        value={{
          visibleSection: visibleSectionState,
          setVisibleSection: setVisibleSectionAction,
          selectedCar: selectedCarState,
          setSelectedCar: setSelectedCarAction,
          datesToBeDisabled: disabledDates,
          setDatesToBeDisabled: setDisabledDates,
          destinationOptions,
          setTargetDestination,
          pointOfDeparture,
          setPointOfDeparture,
          selectedDates,
          setSelectedDates,
          userInfo,
          setUserInfo,
          des,
          setDestination,
          daysInTown,
          setDaysInTown,
          daysOutOfTown,
          setDaysOutOfTown,
          price,
          setPrice,
          selectRange,
          setSelectRange,
          cancelSelectedDates,
          setCancelSelectedDates
        }}
      >
        <Script
          src={`https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyAXHwq33vvh-90WEsFd80hBUnoMP5kswSY`}
        />
        {visibleSectionState === "index" && <CarShowCase />}
        {visibleSectionState === "calculatePrice" && <CalculatePriceForm />}
        {visibleSectionState === "bookACar" && <BookingACarForm />}
        {visibleSectionState === "summary" && <OrderSummary />}
        {visibleSectionState === "successfullOrderPlaced" && <SuccessFullOrder />}
        <ToastContainer />
      </OrderCarContext.Provider>
    </Layout>
  );
};

export default IndexPage;

export function Head() {
  return (
    <>
      <title> Book a car</title>
    </>
  );
}
