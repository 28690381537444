import React, { useContext, useState, useEffect } from "react";
import {
  Stack,
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  Menu,
  Icon
} from "@mui/material";
import Box from '@mui/material/Box';
import "../../../index.css";
import "react-calendar/dist/Calendar.css";
import { Avensis, PradoVx, ToyotaLogo } from "../../../images";
import ProductCard from "../../../components/productPrimaryCard";
import ProductSecondaryCard from "../../../components/productSecondaryCard";
import { CameroonFlag } from "../../../images";
import CountryFlag from "../../countryFlag";
import LoginPage from "../../login";
import { UserContext } from "../../../context/AuthContext";
import { ContentContext } from "../../layout";
import { OrderCarContext } from '../../../context/OrderContext';
import { LogoWhite } from "../../../images";
import { Image } from 'mui-image';
import './style.css';
import NavbarBack from "../../navbarBack";
import { Link } from 'gatsby';
import Lazy from "../../lazyAnimation";
import Skeleton from '@mui/material/Skeleton';
import Loader from '../../Loader'
import axios from "axios"
import { API_URL } from "../../../utils/constants";


const CarShowCase = (props: { loading?: boolean }) => {
  const { translate } = useContext(ContentContext);
  const { loading = false } = props;
  const slideStyle = {
    marginTop: "20px",
    overflowY: "scroll",
    paddingTop: "1px",
    marginRight: "-16px",
    paddingRight: "16px",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    paddingBottom: "1px",
  };
  const [vehicles, setVehicles] = useState([])
  const [products, setProducts] = useState([])
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openDropdownMenu = Boolean(anchorEl);
  const [cars, setCars] = useState([
    {
      id: 'x',
      imageUrl: PradoVx,
      brand: "Toyota",
      name: "Prado Land Cruiser TXL",
      price: 50,
      active: true,
    },
    {
      id: 'x',
      imageUrl: Avensis,
      brand: "Toyota",
      name: "Avensis II",
      price: 25,
      active: false,
    }
  ])

  const { user, isAuth, logOut } = useContext(UserContext);

  const { setSelectedDates } = useContext(OrderCarContext);

  const avatarStyle = {
    width: "60px",
    height: "60px",
    cursor: "pointer",
    background: "#000",
    position: "relatve",
    overflow: "inherit",
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelectedDates([]);
  }, [])

  function stringAvatar(name: string | null) {
    if (!name) return "";
    const names = name.split(" ");
    return {
      children:
        names.length > 1
          ? `${names[0][0].toUpperCase()}${names[1][0].toUpperCase()}`
          : `${names[0][0]}`,
    };
  }
  const getProducts = async () => {

    await axios.get(API_URL + "/stripeListProducts").then((res) => {
      setProducts(res.data.data)
      let products = res.data.data

      products.map((prod: any) => {
        cars.map((car) => {
          if (car.name === prod.name) {
            prod.images.push(car.imageUrl)
            prod.brand = car.brand
            car.id = prod.id
          }
        })

      })
    }).catch((err) => {
      alert('Something went wrong!')
    })
  }

  useEffect(() => {
    getProducts()
  }, [])


  return (
    <>
      {loading ?
        <Loader />
        : (
        <Box sx={{ width: '100%' }} id='container-cars'>
          <Lazy type='slide' delay={150} timeout={1000} direction='left' className='slide-container'>
            <Box className="frst">
              <Box className="snd">
                <Box className='cars'>
                  <Link to='/services'>
                    <NavbarBack title={translate('back_option')} />
                  </Link>
                  <Box
                    sx={{
                      padding: "0.5rem 0 0",
                    }}
                  >
                    <Box sx={{ flexBasis: "65%" }}>
                      <Typography sx={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                        {isAuth
                          ? translate("morning") + ` ${user.displayName?.split(" ")?.[0]}!`
                          : translate("welcome_to_bw")}
                      </Typography>
                      <Typography color='#302f2f' sx={{ fontSize: "14px" }}>
                        {translate('which_car_would_you_want_to_rent_today')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: "25px" }}>
                    <Box display="flex" sx={{ columnGap: "2px", alignItems: "center" }}>
                      <Typography color='#302f2f' sx={{ fontWeight: "bold", fontSize: "0.85rem" }}>
                        {translate("most_popular_car")}
                      </Typography>
                    </Box>
                    <Stack
                      direction="row"
                      spacing={3}
                      sx={slideStyle}
                      className="no__scroll"
                    >
                      {cars.map((car, idx) => (
                        <ProductCard
                          key={idx}
                          imageSrc={car.imageUrl}
                          brand={car.brand}
                          price={car.price}
                          productName={car.name}
                          active={car.active}
                          id={car.id}
                        />
                      ))}
                    </Stack>

                    <Stack
                      direction="row"
                      spacing={3}
                      paddingTop={2}
                      marginRight="-16px"
                      paddingRight={16}
                      overflow="scroll"
                    >
                      <Box
                        component="img"
                        src={ToyotaLogo}
                        width={50}
                        sx={{
                          background: "#f2f2f2",
                          borderRadius: "6px",
                          padding: "4px",
                        }}
                      ></Box>
                    </Stack>

                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      marginTop={3}
                      marginBottom={2}
                      flexWrap="wrap"
                      gap={2}
                    >
                      {cars.map((car, idx) => (
                        <ProductSecondaryCard
                          key={idx}
                          imageSrc={car.imageUrl}
                          brand={car.brand}
                          price={car.price}
                          carName={car.name}
                          id={car.id}
                        />
                      ))}
                    </Stack>
                  </Box>
                  {openLoginModal && (
                    <LoginPage
                      openLoginModal={openLoginModal}
                      setOpenLoginModal={setOpenLoginModal}
                    />
                  )}
                </Box>
              </Box>
            </Box>               
          </Lazy>
        </Box>
  )
}
    </>
  );
};

export default CarShowCase;
