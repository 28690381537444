import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ContentContext } from "../../layout";

const SuccessFullOrder = () => {
  const { translate } = useContext(ContentContext);
    const reloadPage = () => {
        console.log('this is');
        return typeof(window) !== "undefined" ? window.location.reload() : null
    }
  return (
    <>
    <CloseRoundedIcon sx={{position: 'absolute', right: '16px', top: '16px', cursor: 'pointer', zIndex: '111'}} onClick={reloadPage} />
    <Box sx={{ minHeight: `100vh`, minWidth: `100vw`, position: 'relative' }}>
      <Box
        sx={{
          padding: "16px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            width: "200px",
            height: "200px",
            borderRadius: "100px",
            background: "#1dc845ab",
            position: "relative",
            alignSelf: 'center',marginTop: "50%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
          }}
        >
          <DoneRoundedIcon
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontSize: "100px",
              transform: "translate(-50%, -50%)",
              color: '#fff',
              alignSelf: 'center'
            }}
          />
        </Box>
        <Typography sx={{textAlign: 'center', mt: 2}}>
          {translate("success_commande_message")}
        </Typography>
      </Box>
    </Box>
    </>
  );
};

export default SuccessFullOrder;
