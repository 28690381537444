import React, { useContext, useState } from "react";
import {
  Button,
  Divider,
  ListItem,
  ListItemText,
  List,
  Typography,
  ListItemAvatar,
  Alert,
  Radio, RadioGroup, FormControlLabel, FormHelperText
} from "@mui/material";
import Box from '@mui/material/Box';
import { ContentContext } from "../../layout";
import Navbar from "../../navbar";
import { OrderCarContext } from "../../../context/OrderContext";
import axios from "axios";
import { API_URL } from "../../../utils/constants";
import { toast } from "react-toastify";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import {
  CalendarTodayRounded,
  DriveEtaOutlined,
  LocalPhone,
  Place,
} from "@mui/icons-material";
import PinDropIcon from "@mui/icons-material/PinDrop";
import Lazy from "../../lazyAnimation";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {navigate} from "gatsby"


const Summary = () => {
  const { translate } = useContext(ContentContext);
  const {
    selectedDates,
    userInfo,
    pointOfDeparture,
    destinationOptions,
    des,
    price,
    selectedCar,
    daysInTown,
    daysOutOfTown,
    setDaysInTown,
    setDaysOutOfTown,
    setUserInfo,
    setSelectedDates,
    setCancelSelectedDates,
    setPrice,
    setSelectRange,
    setSelectedCar,
    setTargetDestination,
    setDestination,
    setPointOfDeparture,
    setVisibleSection,
    cancelSelectedDates,
  } = useContext(OrderCarContext);
  const [selected, setSelected] = useState('')
  const onSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected((event.target as HTMLInputElement).value)
  }

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const cardCheckout = async () => {
  
      await axios.post(API_URL + "/stripeCreateProductPrice",
      {
        "unit_amount": price,
        "currency":"xaf",
        "product_id": selectedCar?.id
      }
      ).then((res) => {
         axios.post(API_URL + "/stripePayment",
          {
            "price_id": res.data.id,
            name: userInfo.name,
            userPhoneNumber: userInfo.phoneNumber
          }
          ).then((res) => {
            navigate(res.data.url)
          }).catch((err) => {
            //TODO: handle error state 
          })
      }).catch((err) => {
        console.log(err)
      })
  
    }
  
  const onSubmit = async () => {
    setIsSubmitting(true);

    const data = {
      days: selectedDates,
      userName: userInfo.name,
      userPhoneNumber: "+237" + userInfo.phoneNumber,
      pickUpAddress: pointOfDeparture,
      reservationOption: destinationOptions,
      car: selectedCar?.name,
      destination: des,
      total: price,
      daysInTown:
        destinationOptions === "b"
          ? parseInt(daysInTown as unknown as string)
          : null,
      daysOutOfTown:
        destinationOptions === "b"
          ? parseInt(daysOutOfTown as unknown as string)
          : null,
      startDate: selectedDates[0],
      endDate: selectedDates[selectedDates.length - 1],
      language: typeof window !== "undefined" ? localStorage.getItem('defaultLanguage') : null

    };

    await axios
      .post(`${API_URL}/getDays`, {
        carName: selectedCar?.name,
        selectedDates: selectedDates,
      })
      .then((response) => {
        if (response.status === 200) {

          /*if (selected === "card") {
            cardCheckout()
            return
          }*/

          axios
            .post(`${API_URL}/bookCar`, data)
            .then((r) => {
              if (r.status === 200) {
                // reset
                setSelectedCar(null);
                setTargetDestination("i");
                setDestination("");
                setPointOfDeparture("");
                setSelectedDates([]);
                setUserInfo({
                  name: "",
                  phoneNumber: ""
                });
                setDaysInTown(0);
                setDaysOutOfTown(0);
                setPrice(0);
                setSelectRange(false);
                setIsSubmitting(false);
                setVisibleSection("successfullOrderPlaced");
              }
            })
            .catch((e) => {
              setIsSubmitting(false);
              if (e.response.status === 403) {
                toast.error(translate("user_exist"));
              } else {
                toast.error(translate("invalid_data"));
              }
            });
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        if (error.response.status === 402) {
          setCancelSelectedDates(true);
        }

        console.log(error.response.status);
      });
  };

  return (
    <>
      <div id='order-summary'>
        <Lazy type='slide' delay={150} timeout={1000} direction='left'>
          <div className="frst">
            <div className="snd">
                <Box
                  className="cars"
                  sx={{
                    maxWidth: 400,
                    margin: "12px auto",
                    display: "flex",
                    flexDirection: "column",
                    p: 1,
                  }}
                >
                  <Navbar title={`${translate("order_summary")}`} previous="bookACar" />
                  {cancelSelectedDates && (
                    <Alert severity="error" sx={{ marginBottom: "15px" }}>
                      <p
                        onClick={() => {
                          setVisibleSection("calculatePrice");
                          setSelectedDates([]);
                        }}
                      >
                        {translate("some_of_your_selected_dates_are_no_more_available")}
                      </p>
                    </Alert>
                  )}
                  <List
                    sx={{
                      maxWidth: 400,
                      margin: "0 auto",
                      display: "flex",
                      flexDirection: "column",
                      p: 1,
                    }}
                  >
                    {cancelSelectedDates && (
                      <Alert severity="error" sx={{ marginBottom: "15px" }}>
                        <p
                          onClick={() => {
                            setVisibleSection("calculatePrice");
                            setSelectedDates([]);
                          }}
                        >
                          {translate("some_of_your_selected_dates_are_no_more_available")}
                        </p>
                      </Alert>
                    )}
                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                        borderRadius: '5px',
                        boxShadow: '0px 0px 4px #c5c2c2',
                        margin: 'auto'
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0px 16px",
                        }}
                      >
                        <Typography fontWeight={`bold`} color='#302f2f' sx={{ fontSize: '18px' }}>
                          {translate("your_information")}
                        </Typography>
                        <Button
                          onClick={() => setVisibleSection("bookACar")}
                          variant="contained"
                          className="primary"
                          size="small"
                          sx={{
                            height: 40,
                            fontSize: '16px',
                            borderRadius: "6px",
                            textTransform: "initial",
                            padding: '16px',
                            marginTop: '10px',
                          }}
                        >
                          {translate("change")}
                        </Button>
                      </Box>
                      <ListItem>
                        <ListItemAvatar>
                          <PermIdentityIcon />
                        </ListItemAvatar>
                        <ListItemText primary={<Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: 'bold' }}>{translate("name")}</Typography>} secondary={<Typography color='#302f2f' sx={{ fontSize: '14px' }}>{userInfo.name}</Typography>} />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <LocalPhone />
                        </ListItemAvatar>
                        <ListItemText primary={<Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: 'bold' }}>{translate("phone_number")}</Typography>} secondary={<Typography color='#302f2f' sx={{ fontSize: '14px' }}>{userInfo.phoneNumber}</Typography>} />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0px 16px",
                          marginTop: 3,
                        }}
                      >
                        <Typography fontWeight={`bold`} color='#302f2f' sx={{ fontSize: '18px', width: '65%' }} >
                          {translate("order_information")}
                        </Typography>
                        <Button
                          onClick={() => setVisibleSection("calculatePrice")}
                          variant="contained"
                          className="primary"
                          size="small"
                          sx={{
                            height: 40,
                            fontSize: '16px',
                            borderRadius: "6px",
                            textTransform: "initial",
                            padding: '16px',
                          }}
                        >
                          {translate("change")}
                        </Button>
                      </Box>
                      <ListItem>
                        <ListItemAvatar>
                          <DriveEtaOutlined />
                        </ListItemAvatar>
                        <ListItemText primary={<Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: 'bold' }}>{translate("car")}</Typography>} secondary={<Typography color='#302f2f' sx={{ fontSize: '14px' }}>{selectedCar?.name}</Typography>} />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <PinDropIcon />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: 'bold' }}>{translate("pick_up_address")}</Typography>}
                          secondary={<Typography color='#302f2f' sx={{ fontSize: '14px' }}>{pointOfDeparture}</Typography>}
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <Place />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: 'bold' }}>Destination</Typography>}
                          secondary={<Typography color='#302f2f' sx={{ fontSize: '14px' }}>{des || `Ville de Yaoundé.`}</Typography>}
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      <ListItem>
                        <ListItemAvatar>
                          <CalendarTodayRounded />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: 'bold' }}>{translate("start_end_date")}</Typography>}
                          secondary={<Typography color='#302f2f' sx={{ fontSize: '14px' }}>{`${new Date(selectedDates[0])
                            .toDateString()
                            .split(" ")
                            .slice(1)
                            .join(" ")} ${translate("and")} ${new Date(
                              selectedDates[selectedDates.length - 1]
                            )
                              .toDateString()
                              .split(" ")
                              .slice(1)
                              .join(" ")}`}
                          </Typography>}
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </List>
                  </List>
                  <Box sx={{ marginTop: 2 }}>
                    <Box>
                      <Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: 'bold' }}> {translate("payment_method")} </Typography>
                      <RadioGroup
                        aria-labelledby="demo-error-radios"
                        name="payment_methodes"
                        value={selected}
                        onChange={onSelected}
                      >
                        <FormControlLabel value="card" control={<Radio color="default" />} label={<Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: 'bold' }}> {translate('card_payment')} </Typography>} />
                        <FormControlLabel value="mobile" control={<Radio color="default" />} label={<Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: 'bold' }}> {translate('mobile_payment')} </Typography>} />
                      </RadioGroup>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: `center`,
                        padding: "1rem",
                      }}
                    >
                      <Box>
                        <Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: 'bold' }}>Total </Typography>
                        <Typography variant={`caption`} color='#302f2f' sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                          {translate("for")} {selectedDates.length} {translate("day")}(s)
                        </Typography>
                      </Box>
                      <Box>
                        <Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                          <Typography color='#302f2f' sx={{ fontSize: '16px', fontWeight: 'bold' }} marginRight={`4px`}>XAF</Typography>
                          {new Intl.NumberFormat().format(price)}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      fullWidth
                      variant="contained"
                      className="primary"
                      endIcon={<ArrowForwardIcon sx={{ color: 'white' }} />}
                      sx={{
                        marginTop: 2,
                        height: 50,
                        borderRadius: "6px",
                        textTransform: "initial",
                        fontSize: "16px",
                        "&:disabled": {
                          "-webkit-text-fill-color": "#c6c6c6 !important",
                        },
                      }}
                      onClick={onSubmit}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span style={{ color: "#fff!important" }}>
                          {translate("booking_in_progress")}
                        </span>
                      ) : (
                        translate("book_car")
                      )}
                    </Button>
                  </Box>
                </Box>
            </div>
          </div>
        </Lazy>
      </div>
    </>
  );
};

export default Summary;
